import { nanoid } from 'nanoid';
import { combineText } from './combineText';
type ShowMore = {
  link: String;
  text: String;
  images: any;
};
type Options = {
  showMore?: ShowMore;
  linkTitlePlaceholder?: string;
};
type Props = {
  services: any;
  options?: Options;
};

export default ({ services, options }: Props) => {
  if (
    !!options?.showMore &&
    !services.nodes.find(
      (item) =>
        item.service.name === options.showMore.text &&
        item.service.slug === options.showMore.link
    )
  ) {
    services.nodes.push({
      id: nanoid(),
      title: '',
      service: {
        name: options.showMore.text,
        slug: options.showMore.link,
      },
      heroImage: {
        __typename: 'ContentfulAsset',
        localFile_regular_mobile: {
          childImageSharp: {
            gatsbyImageData: {
              ...options.showMore.images.src.ImageSharp_medium_rectangular_2,
              layout: 'fixed',
            },
          },
        },
        ContentfulAsset: {
          ...options.showMore.images.src.ImageSharp_medium_rectangular_2,
          layout: 'fixed',
        },
      },
    });
  }
  return services.nodes.map((item) => ({
    id: item.id,
    linkTitle: combineText(options?.linkTitlePlaceholder, [
      item.service?.name || item?.seoTitle,
    ]),
    title: item?.service?.name || item?.seoTitle,
    slug: item?.service?.slug,
    category: null,
    service: item?.service,
    // timeToRead: 2,
    date: '',
    featured: true,
    thumbnail: {
      __typename: item?.heroImage?.__typename,
      localFile: item?.heroImage?.localFile_regular_mobile,
      ContentfulAsset_vertical: item?.heroImage?.ContentfulAsset,
    },
    author: {},
  }));
};
