import { normalizeBlockContentNodes } from '@blocks-helpers';
import CardList from '@components/CardList';
import Divider from '@components/Divider';
import Pagination from '@components/Pagination';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import { Hero, Main, PreFooter, Stack } from '@layout';
import Footer from '@solid-ui-blocks/Footer/Block01';
import Header from '@solid-ui-blocks/Header/Block01';
import Layout from '@solid-ui-layout/Layout';
import NewsletterExpanded from '@widgets/NewsletterExpanded';
import Seo from '@widgets/Seo';
import React from 'react';
import { merge } from 'theme-ui';
import convertServiceToPost from '../../../functions/convertServiceToPost';
import theme from '../../pages/new-landing/_theme';
import HeroComponent from './components/Hero/Hero';

const ServiceLandings = ({
  data: { paginatedServiceLandings = {}, allBlockContents },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props;
  const content = normalizeBlockContentNodes(allBlockContents.nodes);
  const paginatedPosts = convertServiceToPost({
    services: paginatedServiceLandings,
    options: {
      linkTitlePlaceholder: content['old-serviceLandings']?.text_1?.text,
    },
  });
  return (
    <Layout
      theme={merge(theme, {
        ...flowUITheme,
        colors: { background: '#F8F8F8' },
      })}
      {...props}
    >
      <Seo title='Home' />
      <Header content={content['header']} />
      <Divider space='5' />
      <Hero>
        <HeroComponent {...props} />
      </Hero>
      <Divider />
      <Stack>
        <Main
          sx={{
            zIndex: 1,
            article: {
              a: {
                flex: [1, 1, 1],
                position: 'relative',
                overflow: 'hidden',
              },
            },
          }}
        >
          <CardList
            variant={['horizontal']}
            loading="eager"
            title={content['old-serviceLandings']?.kicker?.text}
            nodes={paginatedPosts}
            columns={[1, 2]}
            withTitleLink
          />
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination
          {...paginatedServiceLandings?.pageInfo}
          basePath='/servicelandings'
          prevText={content['pagination']?.kicker?.textGroup[0]}
          pageText={content['pagination']?.kicker?.textGroup[1]}
          ofText={content['pagination']?.kicker?.textGroup[2]}
          nextText={content['pagination']?.kicker?.textGroup[3]}
        />
      </PreFooter>
      <Divider space={5} />
      <Stack>
        <Main sx={{ zIndex: 1 }}>
          {services.mailchimp && <NewsletterExpanded simple />}
        </Main>
      </Stack>
      <Footer
        content={content['footer']}
        customStyles={{ backgroundColor: 'white' }}
      />
    </Layout>
  );
};

export default ServiceLandings;
