import { graphql } from 'gatsby';
import ServiceLandings from '../../../../../site/src/components/ServiceLandings';

export default ServiceLandings;

export const pageQuery = graphql`
  query serviceLandingsQuery($skip: Int, $limit: Int, $blocksPaths: [String]!) {
    allBlockContents: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: {
          in: ["header", "footer", "old-serviceLandings", "pagination"]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
    paginatedServiceLandings: allServiceLanding(limit: $limit, skip: $skip)
      @include(if: true) {
      nodes {
        id
        service {
          name
          slug
        }
        category {
          ...ArticleCategory
        }
        heroImage {
          __typename
          ... on ContentfulAsset {
            ContentfulAsset: gatsbyImageData(
              width: 250
              height: 200
              layout: FIXED
              cropFocus: TOP
              resizingBehavior: THUMB
              quality: 80
            )
            localFile_regular_mobile: localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 250
                  height: 200
                  layout: FIXED
                  transformOptions: { cropFocus: NORTH }
                  quality: 80
                )
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`;
